<script setup lang="ts">
import { ApiReference } from "@scalar/api-reference";
import "@scalar/api-reference/style.css";

const config = useRuntimeConfig();
config.public.apiBase;

const ScalarConfig = {
  darkMode: false,
  hideDownloadButton: true,
  hideDarkModeToggle: true,
  theme: "kepler",
  spec: {
    url: `${ config.public.apiBase }/api-docs`
  },
  metadata: {
    title: `${ config.public.appName } - Documentation API`,
  }
};
</script>

<script lang="ts">
const config = useRuntimeConfig();
setInterval(() => {
  useHead({
    title: `${ config.public.appName } - Documentation API`
  });
}, 2000);
</script>

<template>
  <div class="h-full overflow-auto">
    <ApiReference
      :configuration="ScalarConfig" />
  </div>
</template>
